import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route, 
} from "react-router-dom";
import Header from './layout/header';
import Login from './auth/login';
import Signup from './auth/signup';
import Passwordrecover from './auth/passwordrecover';
import Dashboard from './components/dashboard';
import Confirmpassword from './auth/confirmpassword';
import Tokencheck from './auth/tokencheck';
import User from './components/user';
function App() {
  return (
   <>
    <Router>
    <Routes>
    <Route exact path="/" element={<Login/>}></Route>
    <Route exact path="/signup" element={<Signup/>}></Route>
    <Route exact path="/passwordrecover" element={<Passwordrecover/>}></Route>
    <Route exact path="/tokencheck" element={<Tokencheck/>}></Route>
    <Route exact path="/confirmpassword" element={<Confirmpassword/>}></Route>
    <Route exact path="/dashboard" element={<Dashboard/>}></Route>
    <Route exact path="/user" element={<User/>}></Route>




    </Routes>
    </Router>

   </>
  );
}

export default App;
