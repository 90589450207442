import { useState, useEffect } from 'react';
import Header from '../layout/header';
import MaterialTable from "material-table";
import { ArrowDownward } from "@material-ui/icons";
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import SaveAlt from '@material-ui/icons/SaveAlt'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import FilterList from '@material-ui/icons/FilterList'
import Remove from '@material-ui/icons/Remove'
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import { Loader } from "react-overlay-loader"
import { axiosInstance } from '../config/https'
import { toast } from "react-toastify";

export default function User() {

    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        GetUser()

    }, [])

    // GET users 
    const GetUser = () => {
        setLoader(true)
        axiosInstance.get("/All/users")
            .then((response) => {
                setLoader(false)
                console.log('res', response)

                if (response?.data?.status) {
                    setData(response.data.data)
                } else {
                    toast.warning(response?.data?.message)
                }
            })



    }

    return (
        <div>

            {loader === true ? <Loader fullPage loading /> : null}

            <Header />
            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                  
                        <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                            <h3 className="content-header-title mb-0 d-inline-block">Users</h3>

                        </div>
                        <div class="content-header-right col-md-6 col-12">
          <div class="dropdown float-md-right">
            <button class="btn btn-danger dropdown-toggle round btn-glow px-2" id="dropdownBreadcrumbButton"
            type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button>
            <div class="dropdown-menu" aria-labelledby="dropdownBreadcrumbButton"><a class="dropdown-item" href="#"><i class="la la-calendar-check-o"></i> Calender</a>
              <a class="dropdown-item" href="#"><i class="la la-cart-plus"></i> Cart</a>
              <a class="dropdown-item" href="#"><i class="la la-life-ring"></i> Support</a>
              <div class="dropdown-divider"></div><a class="dropdown-item" href="#"><i class="la la-cog"></i> Settings</a>
            </div>
          </div>
        </div>
        </div>

        <div className="content-body">

                        <MaterialTable
                            title=""
                            icons={{
                                Check: Check,
                                DetailPanel: ChevronRight,
                                // Delete: DeleteOutline,
                                Export: SaveAlt,
                                Filter: FilterList,
                                FirstPage: FirstPage,
                                LastPage: LastPage,
                                NextPage: ChevronRight,
                                PreviousPage: ChevronLeft,
                                Search: Search,
                                ThirdStateCheck: Remove,
                                Add: Add,
                                SortArrow: ArrowDownward,
                                Clear: Clear,
                                Edit: Edit,
                                ViewColumn: ViewColumn,
                                ResetSearch: Clear
                            }}

                            columns={[
                                { title: 'First Name', field: 'first_name' },
                                { title: 'Last Name', field: 'last_name' },
                                { title: 'Email', field: 'email' },
                                { title: 'Phone', field: 'phone' },

                            ]}
                            data={data}

                            options={{

                                actionsColumnIndex: -1,
                                exportButton: true,
                                headerStyle: {
                                    backgroundColor: '#1E9FF2 ',
                                    color: '#FFF'
                                },
                                cellStyle: {
                                    // backgroundColor: '#191919',
                                    // color: '#fff',
                                },


                            }}
                        />
                    </div>
                </div>
            </div>


        </div>
    )
}
