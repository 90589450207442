import React, { useState } from 'react'
import logo from "../assets/images/logo/rlclogo.png"
import profileimg from "../assets/images/portrait/small/avatar-s-19.png"
import { Link,useNavigate } from 'react-router-dom';
export default function Header() {
    const [profileDrop, setprofileDrop] = useState("");
    const navigate = useNavigate()

    // for sidbar 
    const Toggle = () => {

        var bodyElement = document.body;
        console.log("bodyElement", bodyElement.className)
        if (bodyElement.className == "vertical-layout vertical-menu 2-columns fixed-navbar pace-done menu-expanded") {

            // New class attribute value
            var newClassValue = "vertical-layout vertical-menu 2-columns fixed-navbar pace-done menu-collapsed";
            // Set the new class attribute value
            bodyElement.className = newClassValue;
        }
        else {
            var newClassValue = "vertical-layout vertical-menu 2-columns fixed-navbar pace-done menu-expanded";
            bodyElement.className = newClassValue;



        }


    }

    // for all dropdown 

    const profiledropdown = (e) => {
        console.log("e sas kai", e)
        if (e == profileDrop) {
            setprofileDrop("")
        } else {
            setprofileDrop(e)
        }
    }

    const Logout = ()=>{
        localStorage.clear()
        navigate('/')
    }


    return (
        <div>
            <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-semi-light bg-info navbar-shadow">
                <div className="navbar-wrapper">
                    <div className="navbar-header">
                        <ul className="nav navbar-nav flex-row">
                            <li className="nav-item mobile-menu d-md-none mr-auto">
                                <Link className="nav-link nav-menu-main menu-toggle hidden-xs" to="#">
                                    <i className="ft-menu font-large-1" />
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="navbar-brand" to="index.html">
                                    <img
                                        className="brand-logo"
                                        //   alt="modern admin logo"
                                        src={logo}
                                        width={100}
                                    />
                                    <h3 className="brand-text">RLC Admin</h3>
                                </Link>
                            </li>
                            <li className="nav-item d-md-none">
                                <Link
                                    className="nav-link open-navbar-container"
                                    data-toggle="collapse"
                                    data-target="#navbar-mobile"
                                >
                                    <i className="la la-ellipsis-v" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="navbar-container content">
                        <div className="collapse navbar-collapse" id="navbar-mobile">
                            <ul className="nav navbar-nav mr-auto float-left">
                                <li className="nav-item d-none d-md-block" onClick={Toggle}>
                                    <Link
                                        className="nav-link nav-menu-main menu-toggle hidden-xs"
                                        to="#"
                                    >
                                        <i className="ft-menu" />
                                    </Link>
                                </li>
                                <li className="nav-item d-none d-md-block">
                                    <Link className="nav-link nav-link-expand" to="#">
                                        <i className="ficon ft-maximize" />
                                    </Link>
                                </li>

                                <li className="nav-item nav-search">
                                    <Link className="nav-link nav-link-search" to="#">
                                        <i className="ficon ft-search" />
                                    </Link>
                                    <div className="search-input">
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Explore Modern..."
                                        />
                                    </div>
                                </li>
                            </ul>
                            <ul className="nav navbar-nav float-right">
                                <li onClick={() => profiledropdown("profile")} className={profileDrop == "profile" ? "dropdown dropdown-user nav-item show" : "dropdown dropdown-user nav-item"}>
                                    <Link
                                        className="dropdown-toggle nav-link dropdown-user-link"
                                        to="#"
                                        data-toggle="dropdown"
                                    >
                                        <span className="mr-1">
                                            Hello,
                                            <span className="user-name text-bold-700">John Doe</span>
                                        </span>
                                        <span className="avatar avatar-online">
                                            <img
                                                src={profileimg}
                                                alt="avatar"
                                            />
                                            <i />
                                        </span>
                                    </Link>
                                    <div className={profileDrop == "profile" ? "dropdown-menu dropdown-menu-right show" : "dropdown-menu dropdown-menu-right"}>
                                        <Link className="dropdown-item" to="#">
                                            <i className="ft-user" /> Edit Profile
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                            <i className="ft-mail" /> My Inbox
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                            <i className="ft-check-square" /> Task
                                        </Link>
                                        <Link className="dropdown-item" to="#">
                                            <i className="ft-message-square" /> Chats
                                        </Link>
                                        <div className="dropdown-divider" />
                                        <Link className="dropdown-item" to="#">
                                            <i className="ft-power" /> Logout
                                        </Link>
                                    </div>
                                </li>

                                <li onClick={() => profiledropdown("notification")} className={profileDrop == "notification" ? "dropdown dropdown-notification nav-item show" : "dropdown dropdown-notification nav-item"} >
                                    <Link
                                        className="nav-link nav-link-label"
                                        to="#"
                                        data-toggle="dropdown"
                                    >
                                        <i className="ficon ft-bell" />
                                        <span className="badge badge-pill badge-default badge-danger badge-default badge-up badge-glow">
                                            5
                                        </span>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right"  style={{overflowX:"scroll"}}>
                                        <li className="dropdown-menu-header">
                                            <h6 className="dropdown-header m-0">
                                                <span className="grey darken-2">Notifications</span>
                                            </h6>
                                            <span className="notification-tag badge badge-default badge-danger float-right m-0">
                                                5 New
                                            </span>
                                        </li>
                                        <li className="scrollable-container media-list w-100">
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center">
                                                        <i className="ft-plus-square icon-bg-circle bg-cyan" />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">You have new order!</h6>
                                                        <p className="notification-text font-small-3 text-muted">
                                                            Lorem ipsum dolor sit amet, consectetuer elit.
                                                        </p>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                30 minutes ago
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center">
                                                        <i className="ft-download-cloud icon-bg-circle bg-red bg-darken-1" />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading red darken-1">
                                                            99% Server load
                                                        </h6>
                                                        <p className="notification-text font-small-3 text-muted">
                                                            Aliquam tincidunt mauris eu risus.
                                                        </p>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                Five hour ago
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center">
                                                        <i className="ft-alert-triangle icon-bg-circle bg-yellow bg-darken-3" />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading yellow darken-3">
                                                            Warning notifixation
                                                        </h6>
                                                        <p className="notification-text font-small-3 text-muted">
                                                            Vestibulum auctor dapibus neque.
                                                        </p>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                Today
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center">
                                                        <i className="ft-check-circle icon-bg-circle bg-cyan" />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Complete the task</h6>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                Last week
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left align-self-center">
                                                        <i className="ft-file icon-bg-circle bg-teal" />
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Generate monthly report</h6>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                Last month
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        {/* <li className="dropdown-menu-footer">
                                            <Link
                                                className="dropdown-item text-muted text-center"
                                                to="javascript:void(0)"
                                            >
                                                Read all notifications
                                            </Link>
                                        </li> */}
                                    </ul>
                                </li>
                                <li onClick={() => profiledropdown("message")} className={profileDrop == "message" ? "dropdown dropdown-notification nav-item show" : "dropdown dropdown-notification nav-item"}>
                                    <Link
                                        className="nav-link nav-link-label"
                                        to="#"
                                        data-toggle="dropdown"
                                    >
                                        <i className="ficon ft-mail"> </i>
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-media dropdown-menu-right" style={{overflowX:"scroll"}}>
                                        <li className="dropdown-menu-header">
                                            <h6 className="dropdown-header m-0">
                                                <span className="grey darken-2">Messages</span>
                                            </h6>
                                            <span className="notification-tag badge badge-default badge-warning float-right m-0">
                                                4 New
                                            </span>
                                        </li>
                                        <li className="scrollable-container media-list w-100">
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-online rounded-circle">
                                                            <img
                                                                src={profileimg}
                                                                alt="avatar"
                                                            />
                                                            <i />
                                                        </span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Margaret Govan</h6>
                                                        <p className="notification-text font-small-3 text-muted">
                                                            I like your portfolio, let's start.
                                                        </p>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                Today
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-busy rounded-circle">
                                                            <img
                                                                src={profileimg}

                                                                alt="avatar"
                                                            />
                                                            <i />
                                                        </span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Bret Lezama</h6>
                                                        <p className="notification-text font-small-3 text-muted">
                                                            I have seen your work, there is
                                                        </p>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                Tuesday
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-online rounded-circle">
                                                            <img
                                                                src={profileimg}

                                                                alt="avatar"
                                                            />
                                                            <i />
                                                        </span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Carie Berra</h6>
                                                        <p className="notification-text font-small-3 text-muted">
                                                            Can we have call in this week ?
                                                        </p>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                Friday
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="javascript:void(0)">
                                                <div className="media">
                                                    <div className="media-left">
                                                        <span className="avatar avatar-sm avatar-away rounded-circle">
                                                            <img
                                                                src={profileimg}

                                                                alt="avatar"
                                                            />
                                                            <i />
                                                        </span>
                                                    </div>
                                                    <div className="media-body">
                                                        <h6 className="media-heading">Eric Alsobrook</h6>
                                                        <p className="notification-text font-small-3 text-muted">
                                                            We have project party this saturday.
                                                        </p>
                                                        <small>
                                                            <time
                                                                className="media-meta text-muted"
                                                                dateTime="2015-06-11T18:29:20+08:00"
                                                            >
                                                                last month
                                                            </time>
                                                        </small>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                        {/* <li className="dropdown-menu-footer">
                                            <Link
                                                className="dropdown-item text-muted text-center"
                                                to="javascript:void(0)"
                                            >
                                                Read all messages
                                            </Link>
                                        </li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            <div
                className="main-menu menu-fixed menu-light menu-accordion    menu-shadow "
                data-scroll-to-active="true"
            >
                <div className="main-menu-content">
                    <ul
                        className="navigation navigation-main"
                        id="main-menu-navigation"
                        data-menu="menu-navigation"
                    >
                        <li className=" nav-item">
                            <Link to="/dashboard">
                                <i className="la la-home" />
                                <span className="menu-title" data-i18n="nav.dash.main">
                                    Dashboard
                                </span>
                            
                            </Link>

                        </li>
                        <li className=" nav-item">
                            <Link to="/user">
                                <i className="la la-user" />
                                <span className="menu-title" >
                                    User
                                </span>
                            </Link>
                          
                        </li>

                        <li onClick={Logout} className=" nav-item">
                            <Link to="/">
                                <i className="la la-user" />
                                <span className="menu-title">
                                    Logout
                                </span>
                            </Link>
                          
                        </li>
                       
                      
                        
                  
                                          
                                     
                                       
                                       
                                     
                          
                     
                    </ul>
                </div>
            </div>

        </div>
    )
}
