import axios from "axios";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  baseURL: "https://rlc.dev-oa.xyz/api",
});

const token = localStorage.getItem("Token");
const urlParams = new URLSearchParams(window.location.search);
let t = urlParams.get('token');

axiosInstance.defaults.headers.common["Authorization"] = t ? `Bearer ${t}` : `Bearer ${token}`;
axiosInstance.interceptors.request.use(
  async (config) => {
    //   const token = # Your token goes over here;
    if (token) {
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response !== undefined) {
      const Data = error.response.data;
      if (Data.errors) {
        toast.error(Data.error);
      } else {
        toast.error(Data.message);
      }
    } else {
      toast.error(error.message);
    }
    return error.response
  }
);