import React, { useState } from 'react'
import logodark from "../assets/images/logo/rlclogo.png"
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { axiosInstance } from '../config/https'
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader"

export default function Confirmpassword() {
    const navigate = useNavigate()
    const location = useLocation();
    const [newpassword, setNewpassword] = useState("");
    const [confirmpass, setConfirmpass] = useState("");
    const [loader, setLoader] = useState(false);
    console.log("location", location.state)

    const ForgetPassword = () => {
        setLoader(true)

        if (newpassword === confirmpass) {


            //  fromdata data passing 
            const formData = new FormData()
            formData.append("email", location.state.data)
            formData.append("remember_token", location.state.token)
            formData.append("password", confirmpass)



            axiosInstance.post("/reset/user/password", formData)
                .then((response) => {
                    setLoader(false)
                    const Data = response?.data
                    if (Data?.status) {

                        toast.success(Data.message)
                        navigate("/")

                    } else {
                        const keys = Object.keys(response.data.data)
                        keys.map((e) => {
                            toast.warning(response.data.data[e][0])
                        })

                    }
                }).catch(() => {
                    setLoader(false)


                })


        } else {
            toast.error("Password Not Match")
        }

    }
    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="app-content content" style={{ marginLeft: "0px" }}>
                <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        <section className="flexbox-container">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <div className="col-md-4 col-10 box-shadow-2 p-0">
                                    <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                        <div className="card-header border-0 pb-0">
                                            <div className="card-title text-center">
                                                <img
                                                    src={logodark}
                                                    alt="branding logo"
                                                    width={100}

                                                />
                                            </div>
                                            <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                                <span>Create new password</span>
                                            </h6>
                                        </div>
                                        <div className="card-content">
                                            <div className="card-body">
                                                <form
                                                    className="form-horizontal"
                                                    action="login-simple.html"
                                                    noValidate=""
                                                >
                                                    <fieldset className="form-group position-relative has-icon-left">
                                                        <input
                                                            type="password"
                                                            className="form-control form-control-lg input-lg"
                                                            placeholder="New Password"
                                                            value={newpassword}
                                                            onChange={(e) => setNewpassword(e.target.value)}

                                                        />
                                                        <div className="form-control-position">
                                                            <i className="la la-key" />
                                                        </div>
                                                    </fieldset>

                                                    <fieldset className="form-group position-relative has-icon-left">
                                                        <input
                                                            type="password"
                                                            className="form-control form-control-lg input-lg"
                                                            placeholder="Confirm Password"
                                                            value={confirmpass}
                                                            onChange={(e) => setConfirmpass(e.target.value)}

                                                        />
                                                        <div className="form-control-position">
                                                            <i className="la la-key" />
                                                        </div>
                                                    </fieldset>

                                                    <button
                                                        onClick={() => ForgetPassword()}
                                                        type="button"
                                                        className="btn btn-outline-info btn-lg btn-block"
                                                    >
                                                        <i className="ft-unlock" /> Update
                                                    </button>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )

}
