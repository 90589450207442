import React, { useState, useEffect } from 'react'
import logodark from "../assets/images/logo/rlclogo.png"
import { Link,useNavigate } from 'react-router-dom'
import { axiosInstance } from '../config/https'
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader"

export default function Login() {
const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const Login = () => {

        if (email == "" || password == "") {

            toast.error("ALL fields are requird")

        } else {

            setLoader(true)
            const formData = {
                email: email,
                password: password
            };
            axiosInstance.post("/login", formData)
                .then((response) => {
                    setLoader(false)
                    const Data = response?.data
                    if (Data?.status) {
                      
                        toast.success(Data.message)
                        localStorage.setItem('admin', JSON.stringify(Data.data.user));
                        localStorage.setItem('Token', Data.data.token);
                        navigate("/dashboard")

                    } else {
                        toast.warning(response?.data?.message)


                    }
                }).catch(() => {
                    setLoader(false)


                })


        }

    }


    return (
        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="app-content content" style={{ marginLeft: "0px" }}>
                <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        <section className="flexbox-container">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <div className="col-md-4 col-10 box-shadow-2 p-0">
                                    <div className="card border-grey border-lighten-3 m-0">
                                        <div className="card-header border-0">
                                            <div className="card-title text-center">
                                                <div className="p-1">
                                                    <img
                                                        src={logodark}
                                                        alt="branding logo"
                                                        width={100}
                                                    />
                                                </div>
                                            </div>
                                            <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                                <span>Login with RLC</span>
                                            </h6>
                                        </div>
                                        <div className="card-content">
                                            <div className="card-body">
                                                <form
                                                    className="form-horizontal form-simple"
                                                    action="index.html"
                                                    noValidate=""
                                                >
                                                    <fieldset className="form-group position-relative has-icon-left mb-0">
                                                        <input
                                                            type="email"
                                                            className="form-control form-control-lg input-lg"

                                                            placeholder="Your Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                        <div className="form-control-position">
                                                            <i className="ft-user" />
                                                        </div>
                                                    </fieldset>
                                                    <fieldset className="form-group position-relative has-icon-left">
                                                        <input
                                                            type="password"
                                                            className="form-control form-control-lg input-lg"

                                                            placeholder="Enter Password"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <div className="form-control-position">
                                                            <i className="la la-key" />
                                                        </div>
                                                    </fieldset>
                                                    <div className="form-group row">
                                                        <div className="col-md-6 col-12 text-center text-md-left">
                                                            <fieldset>
                                                                <input
                                                                    type="checkbox"
                                                                    id="remember-me"
                                                                    className="chk-remember"
                                                                />
                                                                <label htmlFor="remember-me"> Remember Me</label>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col-md-6 col-12 text-center text-md-right">
                                                            <Link to="/passwordrecover" className="card-link">
                                                                Forgot Password?
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() => Login()}
                                                        type="button"
                                                        className="btn btn-info btn-lg btn-block"
                                                    >
                                                        <i className="ft-unlock" /> Login
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="card-footer">
                                            <div className="">
                                                <p className="float-sm-left text-center m-0">
                                                    <Link to="/passwordrecover" className="card-link">
                                                        Recover password
                                                    </Link>
                                                </p>
                                                <p className="float-sm-right text-center m-0">
                                                    New to Moden Admin?{" "}
                                                    <Link to="/signup" className="card-link">
                                                        Sign Up
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

        </div>
    )
}
