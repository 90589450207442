import React, { useState } from 'react'
import logodark from "../assets/images/logo/rlclogo.png"
import { Link, useNavigate } from 'react-router-dom'
import { axiosInstance } from '../config/https'
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader"

export default function Passwordrecover() {

  const navigate = useNavigate()
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);


  const SendLink = () => {
    if (email == "") {

      toast.error("ALL fields are requird")

    } else {

      setLoader(true)

      // Raw data passig 
      const formData = {
        email: email,

      };
      axiosInstance.post("/forget/password/verification", formData)
        .then((response) => {
          setLoader(false)
          const Data = response?.data
          if (Data?.status) {
         toast.success(Data.message)
            navigate("/tokencheck")

          } else {
            const keys = Object.keys(response.data.data)
            keys.map((e) => {
              toast.warning(response.data.data[e][0])
            })
          }
        }).catch(() => {
          setLoader(false)


        })


    }

  }

  return (

    <div>
            {loader === true ? <Loader fullPage loading /> : null}

      <div className="app-content content" style={{ marginLeft: "0px" }}>
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <section className="flexbox-container">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="col-md-4 col-10 box-shadow-2 p-0">
                  <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                    <div className="card-header border-0 pb-0">
                      <div className="card-title text-center">
                        <img
                          src={logodark}
                          alt="branding logo"
                          width={100}

                        />
                      </div>
                      <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                        <span>We will send you a link to reset password.</span>
                      </h6>
                    </div>
                    <div className="card-content">
                      <div className="card-body">
                        <form
                          className="form-horizontal"
                          action="login-simple.html"
                          noValidate=""
                        >
                          <fieldset className="form-group position-relative has-icon-left">
                            <input
                              type="email"
                              className="form-control form-control-lg input-lg"
                              placeholder="Your Email Address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}

                            />
                            <div className="form-control-position">
                              <i className="ft-mail" />
                            </div>
                          </fieldset>
                          <button
                            onClick={() => SendLink()}
                            type="button"
                            className="btn btn-outline-info btn-lg btn-block"
                          >
                            <i className="ft-unlock" /> Recover Password
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="card-footer border-0">
                      <p className="float-sm-left text-center">
                        <Link to="/" className="card-link">
                          Login
                        </Link>
                      </p>
                      <p className="float-sm-right text-center">
                        New to Modern ?{" "}
                        <Link to="/signup" className="card-link">
                          Create Account
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    </div>
  )
}
