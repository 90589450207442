import React, { useState } from 'react'
import logodark from "../assets/images/logo/rlclogo.png"
import { Link, useNavigate } from 'react-router-dom'
import { axiosInstance } from '../config/https'
import { toast } from "react-toastify";
import { Loader } from "react-overlay-loader"

export default function Tokencheck() {
    const navigate = useNavigate()
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);

    const SendToken = () => {
        setLoader(true)

        axiosInstance.post(`/password/reset/email=${email}&tkn=${code}`)
            .then((response) => {
                setLoader(false)
                const Data = response?.data
                if (Data.status) {
                    console.log("response", Data)
                    toast.success(Data.message)
                    navigate("/confirmpassword", { state: {data: Data.data.email, token:Data.data.token} })
                } else {
                    const keys = Object.keys(response.data.data)
                        keys.map((e) => {
                          toast.warning(response.data.data[e][0])
                        })
                }
            }).catch(() => {
                setLoader(false)


            })




    }


    return (

        <div>
            {loader === true ? <Loader fullPage loading /> : null}

            <div className="app-content content" style={{ marginLeft: "0px" }}>
                <div className="content-wrapper">
                    <div className="content-header row"></div>
                    <div className="content-body">
                        <section className="flexbox-container">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <div className="col-md-4 col-10 box-shadow-2 p-0">
                                    <div className="card border-grey border-lighten-3 px-2 py-2 m-0">
                                        <div className="card-header border-0 pb-0">
                                            <div className="card-title text-center">
                                                <img
                                                    src={logodark}
                                                    alt="branding logo"
                                                    width={100}

                                                />
                                            </div>
                                            <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                                <span>Enter your code!</span>
                                            </h6>
                                        </div>
                                        <div className="card-content">
                                            <div className="card-body">
                                                <form
                                                    className="form-horizontal"
                                                    action="login-simple.html"
                                                    noValidate=""
                                                >

                                                    <fieldset className="form-group position-relative has-icon-left">
                                                        <input
                                                            type="email"
                                                            className="form-control form-control-lg input-lg"

                                                            placeholder="Enter Email"
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                        />
                                                        <div className="form-control-position">
                                                                  <i className="ft-mail" />

                                                        </div>
                                                    </fieldset>

                                                    <fieldset className="form-group position-relative has-icon-left">
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-lg input-lg"
                                                            placeholder="Your Code"
                                                            value={code}
                                                            onChange={(e) => setCode(e.target.value)}

                                                        />
                                                        <div className="form-control-position">
                                                            {/* <i className="ft-mail" /> */}

                                                        </div>
                                                    </fieldset>


                                                    <button
                                                        onClick={() => SendToken()}

                                                        type="button"
                                                        className="btn btn-outline-info btn-lg btn-block"
                                                    >
                                                        <i className="ft-unlock" /> Submit
                                                    </button>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    )
}
